import Api from 'api/index';
import { deepCompare } from 'assets/js/common';
import { selectConfig } from 'redux/selectors';

export const RESET_CONFIG = 'RESET_CONFIG';
export const resetConfig = strOfKeys => ({
  type: RESET_CONFIG,
  strOfKeys,
});

export const PUT_CONFIG = 'PUT_CONFIG';
export const putConfig = (value, ...keys) => {
  return {
    type: PUT_CONFIG,
    value,
    keys,
  };
};

export const CLEAR_CONFIG = 'CLEAR_CONFIG';
export const clearConfig = () => ({
  type: CLEAR_CONFIG,
});

export const RECEIVE_CONFIG = 'RECEIVE_CONFIG';
export const receiveConfig = config => ({
  type: RECEIVE_CONFIG,
  config,
});

export function processLoadedConfig(data) {
  return function (dispatch) {
    dispatch(receiveConfig(data));
  };
}

export function saveConfig(config) {
  return function (dispatch) {
    return Api.saveChangedConfig(config).then(data => {
      dispatch(receiveConfig(data));
      dispatch(setLoading(true));
      return new Promise(resolve => {
        setTimeout(() => {
          dispatch(checkUserStatus());
          dispatch(setLoading(false));
          resolve(true);
        }, 10000);
      });
    });
  };
}

export const LOADING = 'LOADING';
export const setLoading = loading => ({
  type: LOADING,
  loading,
});

export const dispatch = function (type, ...params) {
  return async function (dispatch) {
    return dispatch(actionContainer[type](...params));
  };
};

export const STOP_BOT_CORE = 'STOP_BOT_CORE';
export const stopBotCoreSync = () => ({
  type: STOP_BOT_CORE,
});
export const stopBotCore = function () {
  return async function (dispatch) {
    dispatch(setLoading(true));
    return Api.stopBotCore()
      .then(() => {
        return new Promise(resolve => {
          setTimeout(() => {
            dispatch(stopBotCoreSync());
            resolve(true);
          }, 3000);
        });
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const START_BOT_CORE = 'START_BOT_CORE';
export const startBotCoreSync = () => ({
  type: START_BOT_CORE,
});
export const startBotCore = function () {
  return async function (dispatch) {
    dispatch(setLoading(true));
    return Api.startBotCore()
      .then(() => {
        return new Promise(resolve => {
          setTimeout(() => {
            dispatch(startBotCoreSync());
            resolve(true);
          }, 3000);
        });
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const RECEIVE_EXCHANGES = 'RECEIVE_EXCHANGES';
const receiveExchanges = exchanges => ({
  type: RECEIVE_EXCHANGES,
  exchanges,
});

export const getConnectedExchanges = function () {
  return function (dispatch) {
    return Api.getConnectedExchanges().then(res => {
      dispatch(receiveExchanges(res));
    });
  };
};

export const RECEIVE_IMAP = 'RECEIVE_IMAP';
const receiveImap = imap_listener => ({
  type: RECEIVE_IMAP,
  imap_listener,
});

export const RECEIVE_WEBHOOKS = 'RECEIVE_WEBHOOKS';
const receiveWebhooks = webhooks => ({
  type: RECEIVE_WEBHOOKS,
  webhooks,
});

const changeAuth = function (isAuth) {
  return function (dispatch) {
    dispatch(putState(isAuth, 'isAuth'));
  };
};

export const getIMAP = function () {
  return function (dispatch) {
    return Api.getIMAP().then(res => {
      dispatch(receiveImap(res));
    });
  };
};

export const getWEBHOOKS = function () {
  return function (dispatch) {
    return Api.getWEBHOOKS().then(res => {
      dispatch(receiveWebhooks(res));
    });
  };
};

export const updateWallet = function ({ selected, newWallet }) {
  return function (dispatch) {
    if (
      selected === '' ||
      selected === undefined ||
      selected === null ||
      newWallet === '' ||
      newWallet === undefined ||
      newWallet === null
    ) {
      console.log('error:: Required information missing...');
      // error:: Required information missing...
    } else if (selected === newWallet) {
      console.log('error:: Old Wallet and New Wallet are the same...');
      // error:: 'Old Wallet and New Wallet are the same...', '', new Date().getTime(), false);
    } else {
      Api.updateWallet(selected, newWallet)
        .then(() => {
          dispatch(forceConfig(newWallet, 'gunthy_wallet', 'bot'));
          dispatch(getConnectedExchanges());
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
};

export const SET_STATE = 'SET_STATE';
export const putState = (value, ...keys) => ({
  type: SET_STATE,
  value,
  keys,
});

export const FORCE_CONFIG = 'FORCE_CONFIG';
export const forceConfig = (value, ...keys) => ({
  type: FORCE_CONFIG,
  value,
  keys,
});

export const updateMasterKey = function ({ exch, oldMK, newMK, newMS, gw }) {
  return function (dispatch) {
    if (exch === '' || oldMK === '' || gw === '' || newMK === '' || newMS === '') {
      console.log('Required information missing...');
      return;
    }
    Api.updateMasterKey({ exch, oldMK, newMK, newMS, gw })
      .then(() => {
        dispatch(getConnectedExchanges());
      })
      .catch(error => {
        dispatch(putState(error, 'error'));
      });
  };
};
export const SET_COREMEM_DATA = 'SET_COREMEM_DATA';

export const setCorememData = corememData => {
  return {
    type: SET_COREMEM_DATA,
    corememData,
  };
};

export const processLoadedCorememData = function (data) {
  return function (dispatch) {
    dispatch(setCorememData(data));
    dispatch(setBalances(data));
  };
};

export const SET_LICENSE_DATA = 'SET_LICENSE_DATA';
export const setLicenseData = data => ({
  type: SET_LICENSE_DATA,
  payload: data,
})

export const processLicenseData = data => dispatch => {
  dispatch(setLicenseData(data));
};
export const loadAvailableGUIData = function (keys) {
  return function (dispatch) {
    return Api.loadAvailableGUIData(keys)
      .then(data => {
        if (data.processLoadedCorememData) {
          dispatch(processLoadedCorememData(data.processLoadedCorememData));
        }
        if (data.processLoadedConfig) {
          let config = data.processLoadedConfig;
          if (data.processLoadedConnectedExchanges) config.exchanges = data.processLoadedConnectedExchanges;
          if (data.processLoadedIMAP) config.imap_listener = data.processLoadedIMAP;
          if (data.processLoadedWEBHOOKS) config.webhooks = data.processLoadedWEBHOOKS;
          dispatch(processLoadedConfig(config));
        }
        return data;
      })
      .catch(e => {
        console.log(e);
        return {};
      });
  };
};

let previousBalances = [];

export const setBalances = function ({ memory }) {
  return function (dispatch) {
    const balances = [];
    if (memory) {
      Object.keys(memory).forEach(prop => {
        let [exchange, pair] = prop.split('/');
        balances.push({
          exchange,
          pair,
          quoteBalance: memory[prop]['quoteBalance'],
          baseBalance: Math.max(memory[prop]['baseBalance'], memory[prop]['walletBalance']),
        });
      });
    }
    if (!deepCompare(previousBalances, balances)) {
      previousBalances = balances;
      dispatch(putState(balances, 'balances'));
    }
  };
};

export const login = function (password, twoFA, walletAddress) {
  return async function (dispatch) {
    return Api.login(password, twoFA, walletAddress)
      .then(user => {
        dispatch(changeAuth(true));
        return Promise.resolve(user);
      })
      .catch(error => {
        dispatch(changeAuth(false));
        dispatch(putState(error.response.data, 'error'));
        return Promise.reject(error);
      });
  };
};

export const logout = function () {
  return function (dispatch) {
    localStorage.setItem('token', 'Bearer ');
    return dispatch(checkUserStatus()).then(() => {
      dispatch(clearConfig());
      return true;
    });
  };
};

export const register = function (password, twoFA) {
  return function (dispatch) {
    Api.register(password, twoFA)
      .then(() => {
        dispatch(changeAuth(true));
      })
      .catch(error => {
        dispatch(changeAuth(false));
        dispatch(putState(error, 'error'));
      });
  };
};

export const checkUserStatus = function () {
  return function (dispatch) {
    return Api.checkUserStatus().then(res => {
      const { status, isRegistered, isTwoFA, isDemo, metamask } = res;
      const isAuth = status !== 'error';
      dispatch(changeAuth(isAuth));
      dispatch(putState(isRegistered, 'isRegistered'));
      dispatch(putState(isTwoFA, 'twoFARequired'));
      dispatch(putState(isDemo, 'isDemo'));
      dispatch(putState(metamask, 'metamask'));
      return true;
    });
  };
};

export const SET_ERROR = 'SET_ERROR';
export const setError = (error, previousRoute) => ({
  type: SET_ERROR,
  error,
  previousRoute,
});

export const SET_CURRENT_SYMBOL = 'SET_CURRENT_SYMBOL';
export const setCurrentSymbol = symbol => ({
  type: SET_CURRENT_SYMBOL,
  symbol,
});

export const SET_CURRENT_EXPORT_SYMBOL = 'SET_CURRENT_EXPORT_SYMBOL';
export const setCurrentExportSymbol = symbol => ({
  type: SET_CURRENT_EXPORT_SYMBOL,
  symbol,
});

export const getTwoFA = function () {
  return function () {
    return Api.getTwoFA();
  };
};

export const registerNewLicenses = function (wallet, licenses) {
  return function (dispatch) {
    return Api.registerNewLicenses(wallet, licenses).then(({ status, licenses, message }) => {
      if (status !== 'error') {
        dispatch(putState(licenses, 'licReg', 'corememData'));
      }
      return { status, message };
    });
  };
};

export const SET_ORDERS = 'SET_ORDERS';
export const setOrders = (key, orders) => ({
  type: SET_ORDERS,
  key,
  orders,
});

export const NEW_ORDERS = 'NEW_ORDERS';
export const addNewOrders = (key, orders) => ({
  type: NEW_ORDERS,
  key,
  orders,
});
export const NEW_PNL_HISTORY = 'NEW_PNL_HISTORY';
export const addNewHistory = (key, history) => ({
  type: NEW_PNL_HISTORY,
  key,
  history,
});

export const toggleHG = () => {
  return (dispatch, getState) => {
    const state = getState();
    const config = selectConfig(state);
    config.bot.HG = !config.bot.HG;
    dispatch(saveConfig(config));
  }
}

const actionContainer = {
  resetConfig,
  putConfig,
  receiveConfig,
  processLoadedConfig,
  setLoading,
  saveConfig,
  stopBotCore,
  startBotCore,
  getConnectedExchanges,
  getIMAP,
  getWEBHOOKS,
  login,
  updateWallet,
  putState,
  forceConfig,
  updateMasterKey,
  setCorememData,
  processLoadedCorememData,
  processLicenseData,
  register,
  changeAuth,
  logout,
  checkUserStatus,
  getTwoFA,
  setError,
  setCurrentSymbol,
  setCurrentExportSymbol,
  setOrders,
  addNewOrders,
  addNewHistory,
  loadAvailableGUIData,
};
